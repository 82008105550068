
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('barc_config.publication_type') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col  xs="12" sm="12" md="6">
                        <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('barc_config.publication_type')"
                        label-for="type_name"
                        >
                        <v-select
                          name="university_name"
                          v-model="search.type_name_id"
                          label="text"
                          :placeholder="$t('globalTrans.select')"
                          :options= publicationType
                        />
                        </b-form-group>
                    </b-col>
                    <b-col>
                      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('barc_config.publication_type_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 border" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                            <b-button :variant="data.item.status === 1 ? ' iq-bg-success border' : '  iq-bg-danger border'" size="sm" @click="remove(data.item)">
                                                <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                            </b-button> -->
                                          <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                              <i class="ri-ball-pen-fill"></i>
                                          </a>
                                          <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                                              <i class="fas fa-toggle-on"></i>
                                          </a>
                                          <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 2">
                                              <i class="fa fa-toggle-off"></i>
                                          </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { publicationTypeList, publicationTypeStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
      search: {
        type_name_id: ''
      },
      rows: []
    }
  },
  computed: {
    publicationType: function () {
      const objectData = this.$store.state.incentiveGrant.commonObj.publicationTypeList.filter(item => item.status === 1)
      return objectData.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('barc_config.publication_type_entry') : this.$t('barc_config.publication_type_modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('barc_config.publication_type'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'type_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'type_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, publicationTypeStatus, item, 'incentive_grant')
    },
    loadData () {
      var typeId = ''
      if (this.search.type_name_id) {
          typeId = this.search.type_name_id.value
      }
      const params = Object.assign({}, this.search, { type_name_id: typeId, page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, publicationTypeList, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', response.data.data)
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    }
  }
}
</script>
